import { Button, Form, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createCategoryAction, editCategoryAction, fetchOneCategoryAction } from 'actions/category';




const AddNewCategory = ({ showOffcanvas, setShowOffcanvas, categoryId, setCategoryId }) => {
    // const { loading } = useSelector((state) => state.auth);
    const [category, setCategory] = useState({
            en_title: "",
            en_desc: "",
            fr_title: "",
            fr_desc: "",
            ar_title: "",
            ar_desc: "",
            guides:[]
    });

    const { category: categoryEdited } = useSelector(state => state.categorys)
    const dispatch = useDispatch();

    const handleClose = () => {
        setCategory({
            en_title: "",
            en_desc: "",
            fr_title: "",
            fr_desc: "",
            ar_title: "",
            ar_desc: "",
            guides:[]
        })
        setShowOffcanvas(false)
        setCategoryId(null)
    }
    const handleShow = () => setShowOffcanvas(true);
    const handleAddCategory = async (e) => {
        if (categoryId) {
            dispatch(editCategoryAction( category , categoryId, handleClose, setCategoryId))
        } else {
            dispatch(createCategoryAction( category , handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (categoryId) {
            dispatch(fetchOneCategoryAction(categoryId))
        }
    }, [dispatch, categoryId])
    useEffect(() => {
        if (categoryEdited?._id) {
            setCategory({
                en_title: categoryEdited.en_title,
                en_desc: categoryEdited.en_desc,
                fr_title: categoryEdited.fr_title,
                fr_desc: categoryEdited.fr_desc,
                ar_title: categoryEdited.ar_title,
                ar_desc: categoryEdited.ar_desc,

            })
        }
    }, [categoryEdited])




    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow} style={{marginBottom:"20px"}}>
                Add New Category
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{categoryId ? 'Update Category' : 'Add New Category'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>English Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Title"
                                id="en_title"
                                required
                                value={category.en_title}
                                onChange={(e) =>
                                    setCategory({ ...category, en_title: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>English Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Description"
                                id="en_desc"
                                value={category.en_desc}
                                required
                                onChange={(e) =>
                                    setCategory({ ...category, en_desc: e.target.value })
                                }
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>French Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="titre"
                                id="fr_title"
                                required
                                value={category.fr_title}
                                onChange={(e) =>
                                    setCategory({ ...category, fr_title: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>French Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Description"
                                id="fr_desc"
                                value={category.fr_desc}
                                required
                                onChange={(e) =>
                                    setCategory({ ...category, fr_desc: e.target.value })
                                }
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Arabic Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="عنوان"
                                id="ar_title"
                                required
                                value={category.ar_title}
                                onChange={(e) =>
                                    setCategory({ ...category, ar_title: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Arabic Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Description"
                                id="ar_desc"
                                value={category.ar_desc}
                                required
                                onChange={(e) =>
                                    setCategory({ ...category, ar_desc: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* PassWord  */}
                
                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                /* disabled={loading} */
                                onClick={(e) => handleAddCategory(e)}>
                                {categoryId ? 'Update Category' : 'Add New Category'}
                            </Button>

                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewCategory;