import { createFaq, deleteFaq, editFaq, fetchAllFaq, fetchFaq } from "api";
import { CREATE_FAQ, DELETE_FAQ, EDIT_FAQ, GET_ALL_FAQ, GET_ONE_FAQ, LOADING } from "./constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const fetchAllFaqAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await fetchAllFaq();
    dispatch({ type: GET_ALL_FAQ, payload: data.faqs });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};


export const fetchOneFaqAction = (_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await fetchFaq(_id);
    dispatch({ type: GET_ONE_FAQ, payload: data.faq });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const editFaqAction = (fromData,id,handleClose,setFaqId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await editFaq(fromData,id);
    dispatch({ type: EDIT_FAQ, payload: data.faq });
    handleClose()
    setFaqId()
    toast.success(data.message, {
             
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const deleteFaqAction = (_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await deleteFaq(_id);
    dispatch({ type: DELETE_FAQ, payload: _id });
    toast.success(data.message, {
             
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const createFaqAction = (fromData,handleClose) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await createFaq(fromData);
    dispatch({ type: CREATE_FAQ, payload: data.faq });
    handleClose()
    toast.success(data.message, {
         
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};


