import axios from "axios";
// axios.defaults.withCredentials = true;
// import cloudinary from "cloudinary/lib/cloudinary";
// const API = axios.create({ baseURL: "http://localhost:5033" });
const API = axios.create({ baseURL: "https://api-help-center.iread.tn" });




//user
export const signIn = (fromData) => API.post("/users/signin", fromData);
export const signUp = (fromData) => API.post("/reader/register", fromData);
export const logOut = () => API.get("/reader/logout");
export const current = () => API.get("/users/current");
export const resendLink = () =>
  API.post("/reader/resend_email_confirmation_link");


//packs
/* export const getAllPacks = () => API.get("/main/show_all_pack");
export const getAllPackDetails = (id) =>
  API.post("/main/get_pack_details", { id });*/



  //HELP CENTER API
/* FAQ */
export const fetchAllFaq = () => API.get("/faq/get_all")
export const fetchFaq = (id) => API.get("/faq/get_one/"+id)
export const editFaq = (fromData,id) => API.patch("/faq/update/"+id, fromData)
export const deleteFaq = (id) => API.delete("/faq/delete/"+ id)
export const createFaq = (fromData) => API.post("/faq/create/", fromData)

 /* GUIDE */
 export const fetchAllGuide = () => API.get("/guide/get_all")
 export const fetchGuide = (id) => API.get("/guide/get_one/"+id)
 export const editGuide = (fromData,id) => API.patch("/guide/update/"+id, fromData)
 export const deleteGuide = (id) => API.delete("/guide/delete/"+ id)
 export const createGuide = (fromData) => API.post("/guide/create/", fromData)

/*  CATEGORY */
export const fetchAllCategory = () => API.get("/category/get_all")
 export const fetchCategory = (id) => API.get("/category/get_one/"+id)
 export const editCategory = (fromData,id) => API.patch("/category/update/"+id, fromData)
 export const deleteCategory = (id) => API.delete("/category/delete/"+ id)
 export const createCategory = (fromData) => API.post("/category/create/", fromData)

 /*  Ticket */
export const fetchAllTicket = () => API.get("/support/get_tickets");
export const deleteTicket = (id) => API.delete("/support/delete/"+ id)


//Users
export const getAllUsers = (fromData) =>
  API.get("/admin/show_all_readers", fromData);
export const getUserById = (id) => API.get("/admin/get_user/" + id);
export const editUser = (fromData) => API.put("/admin/update_user", fromData);
export const deleteUser = (id) => API.post("/admin/delete_user", { id });
export const createUser = (fromData) =>
  API.post("/admin/create_user", fromData);

//Approved
export const approveUser = (id) => API.post(`/admin/approved_user`, { id });
export const approveTeacher = (id) => API.post(`/admin/approved_user`, { id });

//Teachers
export const getAllTeachers = (fromData) =>
  API.get("/admin/show_all_teachers", fromData);
export const getTeacherById = (id) => API.get("/admin/get_user/" + id);
export const editTeacher = (fromData) =>
  API.put("/admin/update_teacher", fromData);
export const deleteTeacher = (id) => API.post("/admin/delete_user", { id });
export const createTeacher = (fromData) =>
  API.post("/admin/create_teacher", fromData);

//Packs
export const getAllPacks = (fromData) =>
  API.get("/main/show_all_pack", fromData);
export const getPackById = (id) => API.post("/main/get_pack_details", { id });
export const editPack = (fromData) =>
  API.post("/admin/update_pack_details", fromData);
export const deletePack = (id) => API.post("/admin/delete_pack", { id });
export const createPack = (fromData) =>
  API.post("/admin/create_pack", fromData);
export const getAllBooksInPack = (id) =>
  API.post("/main/get_books_from_pack", { id });
//Books
export const addBookInPack = (book_id, pack_id) =>
  API.post("admin/add_book_to_pack", { book_id, pack_id });
export const deleteBookFromPack = (book_id, pack_id) =>
  API.post("admin/delete_book_from_pack", { book_id, pack_id });
export const generateCode = (num_codes, pack_id) =>
  API.post("/admin/generate_code_in_pack/" + pack_id, { num_codes });

//Books
export const getAllBooks = (fromData) =>
  API.get("/admin/show_all_books", fromData);
export const getBookById = (id) => API.get("/admin/get_book/" + id);
export const editBook = (fromData) => API.put("/admin/update_book", fromData);
export const deleteBook = (id) => API.post("/admin/delete_book", { id });
export const createBook = (fromData) =>
  API.post("/admin/create_book", fromData);

//Sessions
export const getAllSessions = (fromData) =>
  API.get("/admin/sessions", fromData);
export const getSessionById = (id) => API.get("/admin/sessions/" + id);
export const editSession = (fromData) =>
  API.post("/admin/update_session", fromData);
export const deleteSession = (id) => API.post("/admin/delete_session", { id });
export const createSession = (fromData) =>
  API.post("/admin/create_session", fromData);
export const getSessionsFromBook = (book_id) =>
  API.post("/admin/sessions_in_book", { book_id });
export const getSessionByTeacher = (id) =>
  API.get("/admin/sessions_by_teacher/" + id);
export const getReaderInSession = (id) =>
  API.get("/admin/reader_in_session/" + id);
export const presenceAPI = (id, session_id, presence) =>
  API.put("/admin/update_presence/" + session_id + "/" + id, presence);

//FollowedPacks

export const getAllFollowedPacks = (fromData) =>
  API.get("/admin/show_pack_follow_requests", { fromData });
export const approveFollowedPack = (fromData) =>
  API.post("/admin/approve_pack_follow_request", fromData);
export const deleteFollowedPack = (fromData) =>
  API.post("/admin/delete_follow_request", fromData);
export const rejectFollowedPack = (fromData) =>
  API.post("/admin/reject_pack_follow_request", fromData);

//FollowedSessions

export const getAllFollowedSessions = (fromData) =>
  API.get("/admin/show_session_follow_requests", { fromData });
export const approveFollowedSession = (fromData) =>
  API.post("/admin/approve_session_follow_request", fromData);
export const deleteFollowedSession = (fromData) =>
  API.post("/admin/delete_session_follow_request", fromData);
export const rejectFollowedSession = (fromData) =>
  API.post("/admin/reject_session_follow_request", fromData);

//about book
export const getAboutBook = (id) => API.get("/admin/get_about_book/" + id);
export const createAboutBook = (fromData, id) =>
  API.post("/admin/create_about_book/" + id, fromData);
export const updateAboutBook = (fromData, id) =>
  API.put("/admin/update_about_book/" + id, fromData);
export const deleteAboutBook = (id) =>
  API.delete("/admin/delete_about_book/" + id);

// Quizs

export const fetchAllQuizs = () => API.get("/reader/get_all_quizs");
export const importQuiz = (formData) =>
  API.post("/reader/import-quiz-json", formData);
export const assignQuizToUser = (formData) =>
  API.post("/reader/assign_quiz_to_user", formData);
export const addQuizToSession = (formData) =>
  API.post("/admin/add_quiz_to_session", {
    session_id: formData.session_id,
    quiz_token: formData.quiz_token,
  });
export const deleteQuizFromSession = (formData) =>
  API.post("/admin/delete_quiz_from_session", formData);
export const getQuizsInSession = (formData) =>
  API.post("/admin/get_quiz_in_session", formData);
export const getQuizByToken = (formData) =>
  API.post("/reader/quiz_by_token", formData);
export const getResult = (token, user_id) => {
  return API.post("/reader/result", { token, user_id });
};

export const getEssayQuestion = (token, user_id) =>
  API.post("/reader/get-essay-answer", {
    user_id: Number(user_id),
    token: token,
  });
export const validationEssay = (fromData) =>
  API.post("/reader/validate-essay-answer", fromData);

//Codes
export const fetchCodes = (id) => API.get("/admin/code_in_pack/" + id);
export const updateCode = (id, fromData) =>
  API.put("/admin/update_code/" + id, fromData);

//Dashboard admin

export const fetchAnalytics = () => API.get("/admin/get_dashboard_analytics");


