import { CREATE_FAQ, DELETE_FAQ, EDIT_FAQ, GET_ALL_FAQ,  GET_ONE_FAQ } from "../actions/constants";

const faqReducer = (state = { faqs:[],faq:{} }, action) => {
  switch (action.type) {
    case GET_ALL_FAQ:
      return { ...state, faqs: action?.payload };
      case GET_ONE_FAQ:
        return { ...state, faq: action?.payload };
      case EDIT_FAQ:
        return { ...state, faqs: state.faqs.map(e=>{
          if(e._id==action.payload._id){
            return action.payload
          }
          else{
            return e
          }
        }) };
      case DELETE_FAQ:
        return { ...state, faqs: state.faqs.filter(e => e._id !== action.payload) };
      case CREATE_FAQ:
        return { ...state, faqs: [...state.faqs, action.payload] };
    default:
      return state;
  }
};

export default faqReducer;
