// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from 'react-bootstrap';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AddNewFaq from './AddNewFaq';
import EnglishFAQ from './EnglishFAQ';
import FrenchFAQ from './FrenchFAQ';
import ArabicFAQ from './ArabicFAQ';


const FaqPage = () => {
	const [faqId, setFaqId] = useState(null)
	//calendar
	const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
	useEffect(() => {
		if (faqId) setShowEventOffcanvas(true)
	}, [faqId])

	return (
		<Fragment>
			<Tab.Container defaultActiveKey="en">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									FAQ's
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">FAQ's management</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							{/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}


						</div>
						<Col className="d-flex justify-content-end">
							<div>
							      	<AddNewFaq
									showOffcanvas={showEventOffcanvas}
									setShowOffcanvas={setShowEventOffcanvas}
									setFaqId={setFaqId}
									faqId={faqId}
								/>
								
							</div>
						</Col>
					</Col>
				</Row>
				<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="en" className="mb-sm-3 mb-md-0">
											English
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="fr" className="mb-sm-3 mb-md-0">
										French
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="ar" className="mb-sm-3 mb-md-0">
											Arabic
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="en" className="pb-4">
										<EnglishFAQ setFaqId={setFaqId} />
									</Tab.Pane>
									<Tab.Pane eventKey="fr" className="pb-4">
										<FrenchFAQ setFaqId={setFaqId} />
									</Tab.Pane>
									<Tab.Pane eventKey="ar" className="pb-4">
                                    <ArabicFAQ setFaqId={setFaqId} />
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
			</Tab.Container>
			<ToastContainer />
		</Fragment>
		
	);
};

export default FaqPage;
