import {
  GET_ALL_BOOKS,
  GET_BOOK,
  EDIT_BOOK,
  DELETE_BOOK,
  CREATE_BOOK,
  CREATE_ABOUT_BOOK,
  UPDATE_ABOUT_BOOK,
  DELETE_ABOUT_BOOK,
  GET_ABOUT_BOOK,
} from "../actions/constants";

const booksReducer = (
  state = { books: [], book: {}, about_book: null },
  action
) => {
  switch (action.type) {
    case GET_ALL_BOOKS:
      return { ...state, books: action?.payload };
    case GET_BOOK:
      return { ...state, book: action?.payload };
    case EDIT_BOOK:
      return {
        ...state,
        books: [
          ...state.books.filter((e) => e.id !== action.payload.id),
          action.payload,
        ],
      };
    case DELETE_BOOK:
      return {
        ...state,
        books: state.books.filter((e) => e.id !== action.payload),
      };
    case CREATE_BOOK:
      return { ...state, books: [...state.books, action.payload] };
    case CREATE_ABOUT_BOOK:
      return { ...state, about_book: action.payload };
    case UPDATE_ABOUT_BOOK:
      return { ...state, about_book: action.payload };
    case DELETE_ABOUT_BOOK:
      return { ...state, about_book: null };
    case GET_ABOUT_BOOK:
    
      return { ...state, about_book: action.payload };
    default:
      return state;
  }
};

export default booksReducer;
