import { deleteTicket, fetchAllTicket } from "api";
import { DELETE_TICKET, GET_ALL_TICKET, LOADING } from "./constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const fetchAllTicketAction = () => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await fetchAllTicket();
      dispatch({ type: GET_ALL_TICKET, payload: data });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };
  export const deleteTicketAction = (_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await deleteTicket(_id);
      dispatch({ type: DELETE_TICKET, payload: _id });
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };