import React from "react";
import { connect } from "react-redux";
import { changeSkin } from "actions"; 

const AppProvider = ({ version, skin, changeSkin, children }) => {
  const setAppConfig = (newStat) => {
    changeSkin(newStat);
  };

  return <div>{children}</div>;
};

const mapStateToProps = (state) => ({
  version: state.version,
  skin: state.skin,
});

const mapDispatchToProps = {
  changeSkin,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppProvider);
