import { v4 as uuid } from "uuid";

const NavbarHome = [
  {
    id: uuid(),
    menuitem: "Packs",
    link: "/marketing/course-category",
  },
  {
    id: uuid(),
    menuitem: "Pricing",
    link: "/marketing/pages/pricing",
  },

  {
    id: uuid(),
    menuitem: "About us",
    link: "/marketing/pages/about",
  },
  {
    id: uuid(),
    menuitem: "Contact us",
    link: "/marketing/pages/contact",
  },
];

export default NavbarHome;
