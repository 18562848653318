import { DELETE_TICKET, GET_ALL_TICKET } from "../actions/constants";

const ticketReducer = (state = { tickets:[],ticket:{} }, action) => {
  switch (action.type) {
    case GET_ALL_TICKET:
      return { ...state, tickets: action?.payload };
      case DELETE_TICKET:
        return { ...state, tickets: state.tickets.filter(e => e._id !== action.payload) };
    default:
      return state;
  }
};

export default ticketReducer;
