import { createGuide, deleteGuide, editGuide, fetchAllGuide, fetchGuide } from "api";
import { CREATE_GUIDE, DELETE_GUIDE, EDIT_GUIDE, GET_ALL_GUIDE, GET_ONE_GUIDE, LOADING } from "./constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const fetchAllGuideAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await fetchAllGuide();
    dispatch({ type: GET_ALL_GUIDE, payload: data.guides });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};


export const fetchOneGuideAction = (_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await fetchGuide(_id);
    dispatch({ type: GET_ONE_GUIDE, payload: data.guide });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const editGuideAction = (fromData,id,handleClose,setGuideId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await editGuide(fromData,id);
    dispatch({ type: EDIT_GUIDE, payload: data.guide });
    handleClose()
    setGuideId()
    toast.success(data.message, {
             
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const deleteGuideAction = (_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await deleteGuide(_id);
    dispatch({ type: DELETE_GUIDE, payload: _id });
    toast.success(data.message, {
             
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const createGuideAction = (fromData,handleClose) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
  
    const {data} = await createGuide(fromData);
    dispatch({ type: CREATE_GUIDE, payload: data.guide });
    handleClose()
    toast.success(data.message, {
         
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};


