import { v4 as uuid } from "uuid";
/**
 *  All Dashboard Routes
 *
 *  Understanding name/value pairs for Dashboard routes
 *
 *  Applicable for main/root/level 1 routes
 *  icon 		: String - It's only for main menu or you can consider 1st level menu item to specify icon name.
 * 				: Object - Icon as an object added from v1.4.0.
 *
 *  Applicable for main/root/level 1 and subitems routes
 * 	id 			: Number - You can use uuid() as value to generate unique ID using uuid library, you can also assign constant unique ID for react dynamic objects.
 *  title 		: String - If menu contains childern use title to provide main menu name.
 *  badge 		: String - (Optional - Default - '') If you specify badge value it will be displayed beside the menu title or menu item.
 * 	badgecolor 	: String - (Optional - Default - 'primary' ) - Used to specify badge background color.
 *
 *  Applicable for subitems / children items routes
 *  name 		: String - If it's menu item in which you are specifiying link, use name ( don't use title for that )
 *  children	: Array - Use to specify submenu items
 *
 *  Used to segrigate menu groups
 *  grouptitle : Boolean - (Optional - Default - false ) If you want to group menu items you can use grouptitle = true,
 *  ( Use title : value to specify group title  e.g. COMPONENTS , DOCUMENTATION that we did here. )
 *
 */

// import MDI icons
import Icon from "@mdi/react";
import { mdiTrello, mdiCalendar } from "@mdi/js";


export const DashboardMenu = [
  {
    id: uuid(),
    title: "Help Center",
    icon: "help-circle",
    children: [
      {
        id: uuid(),
        link: "/faq_management",
        name: "FAQ Management",
      },
   
      {
        id: uuid(),
        link: "/guide_management",
        name: "GUIDE Management",
      },
         {
        id: uuid(),
        link: "/category_management",
        name: "GUIDE Category",
      },
    ],
  },
  {
    id: uuid(),
    title: "Support",
    icon: "mail",
    children: [
      { id: uuid(), link: "/tickets/get_all", name: "Check Tickets" },
    ],
  },

  // {
  //   id: uuid(),
  //   title: "Books management",
  //   icon: "book",
  //   children: [
  //     // { id: uuid(), link: '/courses/all-courses', name: 'All Courses' },
  //     { id: uuid(), link: "/books-management/all-books", name: "Books" },
  //     {
  //       id: uuid(),
  //       link: "/books-management/all-packs",
  //       name: "Packs",
  //     },
  //     {
  //       id: uuid(),
  //       link: "/books-management/all-sessions",
  //       name: "Sessions",
  //     },

  //   ],
  // },
  // {
  //   id: uuid(),
  //   title: "User management",
  //   icon: "user",
  //   children: [
  //     { id: uuid(), link: "/users_management/teachers", name: "Teachers" },
  //     { id: uuid(), link: "/users_management/readers", name: "Readers" },
  //   ],
  // },

  // {
  //   id: uuid(),
  //   title: "Orders",
  //   icon: "dollar-sign",
  //   children: [
  //     {
  //       id: uuid(),
  //       link: "/orders/pack-subscription",
  //       name: "Pack Subscription",
  //     },
  //     {
  //       id: uuid(),
  //       link: "/orders/session-subscription",
  //       name: "Session Subscription",
  //     },
  //   ],
  // },
 
 
];

export default DashboardMenu;
export const DashboardTeacher = [
  
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    children: [
      { id: uuid(), link: "/dashboard/overvien", name: "Overview" },
      { id: uuid(), link: "/dashboard/analytics", name: "Analytics" },
    ],
  },
  {
    id: uuid(),
    title: "Teacher Dashboard",
    icon: "users",
    children: [
      {
        id: uuid(),
        link: "/teacher-dashboard/sessions",
        name: "My Sessions",
      },
      {
        id: uuid(),
        link: "/teacher-dashboard/my-calendar",
        name: "My Calendar",
      },
    ],
  },
]