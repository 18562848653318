export const AUTH = "AUTH";
export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const CLEAR_CURENT = "CLEAR_CURENT";

/* //packs constants
export const GET_ALL_PAKCS = "GET_ALL_PAKCS";
export const GET_PACK_DETAILS = "GET_PACK_DETAILS";
export const GET_BOOKS_IN_PACK = " GET_BOOKS_IN_PACK"; */

//Users constants
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER = "CREATE_USER";

//Approve User
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_TEACHER_SUCCESS = "APPROVE_TEACHER_SUCCESS";

//Teachers constants
export const GET_ALL_TEACHERS = "GET_ALL_TEACHERS";
export const GET_TEACHER = "GET_TEACHER";
export const EDIT_TEACHER = "EDIT_TEACHER";
export const DELETE_TEACHER = "DELETE_TEACHER";
export const CREATE_TEACHER = "CREATE_TEACHER";

//Packs constants
export const GET_ALL_PACKS = "GET_ALL_PACKS";
export const GET_PACK = "GET_PACK";
export const EDIT_PACK = "EDIT_PACK";
export const DELETE_PACK = "DELETE_PACK";
export const CREATE_PACK = "CREATE_PACK";
export const GET_BOOKS_IN_PACK = "GET_BOOKS_IN_PACK";
export const ADD_BOOKS_IN_PACK = "ADD_BOOKS_IN_PACK";
export const DELETE_BOOKS_FROM_PACK = "DELETE_BOOKS_FROM_PACK";
export const GENERATE_CODE = "GENERATE_CODE";

//Books constants
export const GET_ALL_BOOKS = "GET_ALL_BOOKS";
export const GET_BOOK = "GET_BOOK";
export const EDIT_BOOK = "EDIT_BOOK";
export const DELETE_BOOK = "DELETE_BOOK";
export const CREATE_BOOK = "CREATE_BOOK";

//Sessions constants
export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const GET_SESSION = "GET_SESSION";
export const EDIT_SESSION = "EDIT_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const CREATE_SESSION = "CREATE_SESSION";
export const GET_SESSIONS_FROM_BOOK = "GET_SESSIONS_FROM_BOOK";
export const GET_SESSIONS_BY_TEACHER = "GET_SESSIONS_BY_TEACHER";
export const GET_READER_IN_SESSION = "GET_READER_IN_SESSION";
export const PRESENCE_ACTION = "PRESENCE_ACTION";

//FollowedPacks constants
export const GET_ALL_FOLLOWED_PACKS = "GET_ALL_FOLLOWED_PACKS";
export const APPROVE_FOLLOWED_PACK = "APPROVE_FOLLOWED_PACK";
export const DELETE_FOLLOWED_PACK = "DELETE_FOLLOWED_PACK";
export const REJECT_FOLLOWED_PACK = "REJECT_FOLLOWED_PACK";

//FollowedSessions constants
export const GET_ALL_FOLLOWED_SESSIONS = "GET_ALL_FOLLOWED_SESSIONS";
export const APPROVE_FOLLOWED_SESSION = "APPROVE_FOLLOWED_SESSION";
export const DELETE_FOLLOWED_SESSION = "DELETE_FOLLOWED_SESSION";
export const REJECT_FOLLOWED_SESSION = "REJECT_FOLLOWED_SESSION";

//QUizs constants
export const GET_ALL_QUIZS = "GET_ALL_QUIZS";
export const GET__QUIZS_IN_SESSION = "GET__QUIZS_IN_SESSION";
export const ADD__QUIZS_TO_SESSION = "ADD__QUIZS_TO_SESSION";
export const DELETE__QUIZS_FROM_SESSION = "DELETE__QUIZS_FROM_SESSION";
export const GET_RESULT_IN_SESSION = "GET_RESULT_IN_SESSION";
export const GET_ESSAY_QUESTIONS = "GET_ESSAY_QUESTIONS";
export const VALIDATE_ESSAY_QUESTIONS = "VALIDATE_ESSAY_QUESTIONS";
export const GET_RESULT_FOR_EACH_READER = "GET_RESULT_FOR_EACH_READER";

//about book constants
export const GET_ABOUT_BOOK = "GET_ABOUT_BOOK";
export const CREATE_ABOUT_BOOK = "CREATE_ABOUT_BOOK";
export const UPDATE_ABOUT_BOOK = "UPDATE_ABOUT_BOOK";
export const DELETE_ABOUT_BOOK = "DELETE_ABOUT_BOOK";

//Codes
export const GET_CODES = "GET_CODES";
export const EDIT_CODES = "EDIT_CODES";

// Dashbord admin

export const FETCH_DASHBORD_ANALYTICS = "FETCH_DASHBORD_ANALYTICS";
export const FETCH_DASHBORD_ADMIN = "FETCH_DASHBORD_ADMIN";

//API HELP CENTER
/* FAQ */
export const GET_ONE_FAQ = "GET_ONE_FAQ";
export const EDIT_FAQ = "EDIT_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const CREATE_FAQ = "CREATE_FAQ";
export const GET_ALL_FAQ = "GET_ALL_FAQ";

/* GUIDE */
export const GET_ONE_GUIDE = "GET_ONE_GUIDE";
export const EDIT_GUIDE = "EDIT_GUIDE";
export const DELETE_GUIDE = "DELETE_GUIDE";
export const CREATE_GUIDE = "CREATE_GUIDE";
export const GET_ALL_GUIDE = "GET_ALL_GUIDE";

/* CATEGORY */
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";

/* TICKET */
export const GET_ALL_TICKET = "GET_ALL_TICKET";
export const DELETE_TICKET = "DELETE_TICKET";
