import {
    GET_ALL_FOLLOWED_PACKS,
    APPROVE_FOLLOWED_PACK,
    DELETE_FOLLOWED_PACK,
    REJECT_FOLLOWED_PACK,
  } from "../actions/constants";
  
  const followedPackReducer = (state = { followedpacks:[], followedpack: {} }, action) => {
    switch (action.type) {
      case GET_ALL_FOLLOWED_PACKS:
        return { ...state, followedpacks: action?.payload };
      case APPROVE_FOLLOWED_PACK:
        return { ...state, followedpacks: state.followedpacks.map((e)=>{
          if (e.pack_id === action.payload.pack_id && e.user_id ===action.payload.user_id)
          {return {...e,approved:true}}
          else {
            return e
          }

        }) };
        case DELETE_FOLLOWED_PACK: 
        return {  ...state, followedpacks: state.followedpacks.filter(e =>!( e.user_id == action.payload.user_id && e.pack_id == action.payload.pack_id)) }; 
       
        case REJECT_FOLLOWED_PACK:
          return { ...state, followedpacks: state.followedpacks.map((e)=>{
            if (e.pack_id === action.payload.pack_id && e.user_id ===action.payload.user_id)
            {return {...e,approved:false}}
            else {
              return e
            }
  
          }) };
      default:
        return state;
    }
  };
  
  export default followedPackReducer;
  