import {
  AUTH,
  CLEAR_CURENT,
  FETCH_DASHBORD_ANALYTICS,
  LOADING,
  LOGIN,
  LOGOUT,
} from "./constants";
import { signUp, signIn, current, logOut, fetchAnalytics } from "api";
import { toast } from "react-toastify";
export const signUpAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //signup the user
    await signUp(formData);
    // console.log(data?.data?.user);

    dispatch({ type: LOADING, payload: false });
    window.location.href = "/authentication/mail-confirmation";
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const signInAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const {data} =await signIn(formData);
    console.log(data)
   
      dispatch({ type: AUTH, payload: data});
  

    dispatch({ type: LOADING, payload: false });
   
    window.location.href = "/faq_management";
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
      // console.log(error.response.data.message);
    }
  }
};
export const authAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //current the user
    const data = await current();
    if (data?.data?.is_authenticated) {
      dispatch({ type: AUTH, payload: data?.data });
    } else {
      dispatch({ type: CLEAR_CURENT });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: CLEAR_CURENT });
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const logOutAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //logout the user
    // await logOut();

    dispatch({ type: LOGOUT });
    window.location.href = "/";

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const getAnalytics = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchAnalytics();

    dispatch({ type: FETCH_DASHBORD_ANALYTICS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
