import { Button, Form, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createGuideAction, editGuideAction, fetchOneGuideAction } from 'actions/guide';
import AutocompleteCategory from './AutocompleteCategory';




const AddNewGuide = ({ showOffcanvas, setShowOffcanvas, guideId, setGuideId }) => {
    // const { loading } = useSelector((state) => state.auth);
    const [selectedOption, setSelectedOption] = useState(null)
    const [guide, setGuide] = useState({
        en_articletitle: "",
        en_content: "",
        fr_articletitle: "",
        fr_content: "",
        ar_articletitle: "",
        ar_content: "",
        category: "",

    });

    const { guide: guideEdited } = useSelector(state => state.guides)
    const dispatch = useDispatch();

    const handleClose = () => {
        setGuide({
            en_articletitle: "",
            en_content: "",
            fr_articletitle: "",
            fr_content: "",
            ar_articletitle: "",
            ar_content: "",
            category: "",
        })
        setShowOffcanvas(false)
        setGuideId(null)
    }
    const handleShow = () => setShowOffcanvas(true);
    const handleAddGuide = async (e) => {
        if (guideId) {
            dispatch(editGuideAction(guide, guideId, handleClose, setGuideId))
        } else {
            dispatch(createGuideAction(guide, handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (guideId) {
            dispatch(fetchOneGuideAction(guideId))
        }
    }, [dispatch, guideId])
    useEffect(() => {
        if (guideEdited?._id) {
            setGuide({
                en_articletitle: guideEdited.en_articletitle,
                en_content: guideEdited.en_content,
                fr_articletitle: guideEdited.fr_articletitle,
                fr_content: guideEdited.fr_content,
                ar_articletitle: guideEdited.ar_articletitle,
                ar_content: guideEdited.ar_content,
                category: guideEdited.category,

            })
        }
    }, [guideEdited])




    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow} style={{ marginBottom: "20px" }}>
                Add New Guide
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{guideId ? 'Update Guide' : 'Add New Guide'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>English Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Title"
                                id="en_articletitle"
                                required
                                value={guide.en_articletitle}
                                onChange={(e) =>
                                    setGuide({ ...guide, en_articletitle: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>English Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Content"
                                id="en_content"
                                value={guide.en_content}
                                required
                                onChange={(e) =>
                                    setGuide({ ...guide, en_content: e.target.value })
                                }
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>French Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="titre"
                                id="fr_articletitle"
                                required
                                value={guide.fr_articletitle}
                                onChange={(e) =>
                                    setGuide({ ...guide, fr_articletitle: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>French Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Contenu"
                                id="fr_content"
                                value={guide.fr_content}
                                required
                                onChange={(e) =>
                                    setGuide({ ...guide, fr_content: e.target.value })
                                }
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Arabic Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="عنوان"
                                id="ar_articletitle"
                                required
                                value={guide.ar_articletitle}
                                onChange={(e) =>
                                    setGuide({ ...guide, ar_articletitle: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Arabic Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="محتوى"
                                id="ar_content"
                                value={guide.ar_content}
                                required
                                onChange={(e) =>
                                    setGuide({ ...guide, ar_content: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* PassWord  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <AutocompleteCategory
                                selectedOption={guide.category}
                                setSelectedOption={(selectedCategory) => setGuide({ ...guide, category: selectedCategory})}
                            />
                        </Form.Group>

                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                /* disabled={loading} */
                                onClick={(e) => handleAddGuide(e)}>
                                {guideId ? 'Update Guide' : 'Add New Guide'}
                            </Button>

                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewGuide;