// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from 'react-bootstrap';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import AddNewGuide from './AddNewGuide';
import EnglishGuide from './EnglishGuide';
import FrenchGuide from './FrenchGuide';
import ArabicGuide from './ArabicGuide';


const GuidePage = () => {
	const [guideId, setGuideId] = useState(null)
	//calendar
	const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
	useEffect(() => {
		if (guideId) setShowEventOffcanvas(true)
	}, [guideId])

	return (
		<Fragment>
			<Tab.Container defaultActiveKey="en">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Guides
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">Guides management</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							{/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}


						</div>
						<Col className="d-flex justify-content-end">
							<div>
							      	<AddNewGuide
									showOffcanvas={showEventOffcanvas}
									setShowOffcanvas={setShowEventOffcanvas}
									setGuideId={setGuideId}
									guideId={guideId}
								/>
								
							</div>
						</Col>
					</Col>
				</Row>
				<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="en" className="mb-sm-3 mb-md-0">
											English
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="fr" className="mb-sm-3 mb-md-0">
										French
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="ar" className="mb-sm-3 mb-md-0">
											Arabic
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="en" className="pb-4">
										<EnglishGuide setGuideId={setGuideId} />
									</Tab.Pane>
									<Tab.Pane eventKey="fr" className="pb-4">
										<FrenchGuide setGuideId={setGuideId} />
									</Tab.Pane>
									<Tab.Pane eventKey="ar" className="pb-4">
                                    <ArabicGuide setGuideId={setGuideId} />
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
			</Tab.Container>
			<ToastContainer />
		</Fragment>
		
	);
};

export default GuidePage;
