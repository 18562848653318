import {
  GET_ALL_TEACHERS,
  GET_TEACHER,
  EDIT_TEACHER,
  DELETE_TEACHER,
  CREATE_TEACHER,
  APPROVE_TEACHER_SUCCESS,
} from "../actions/constants";

const teachersReducer = (state = { teachers: [], teacher: {} }, action) => {
  switch (action.type) {
    case GET_ALL_TEACHERS:
      return { ...state, teachers: action?.payload.teachers };
    case GET_TEACHER:
      return { ...state, teacher: action?.payload };
    case EDIT_TEACHER:
      return { ...state, teachers: [...state.teachers.filter(e => e.id !== action.payload.id), action.payload]};
      
    case DELETE_TEACHER:
      return { ...state, teachers: state.teachers.filter(e => e.id !== action.payload) };
    case CREATE_TEACHER:
      return { ...state, teachers: [...state.teachers, action.payload] };
    case APPROVE_TEACHER_SUCCESS:
      const updatedTeachers = state.teachers.map(teacher => {
        if (teacher.id === action.payload) {
          return { ...teacher, approved: true };
        }
        return teacher;
      });
      return { ...state, teachers: updatedTeachers };

    default:
      return state;
  }
};

export default teachersReducer;
