// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import {
	Dropdown,
	Image,
	OverlayTrigger,
	Tooltip,
	Row,
	Col,
	Table,
	Button,
	Modal
} from 'react-bootstrap';
import { MoreVertical, Trash, Edit, Mail } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import { deleteGuideAction, fetchAllGuideAction} from 'actions/guide';




const FrenchGuide = ({ setGuideId }) => {


	const { guides } = useSelector(state => state.guides);
	const dispatch=useDispatch();
	useEffect(() => {
	  dispatch(fetchAllGuideAction())
	}, [dispatch])

	const handleDeleteGuide = (id) => {
		setGuideToDelete(id);
		setShowDeleteModal(true);
	};

	const confirmDeleteGuide = () => {
		if (guideToDelete && confirmationMessage === 'DELETE GUIDE') {
		  dispatch(deleteGuideAction(guideToDelete));
		  setGuideToDelete(null);
		  setShowDeleteModal(false);
		  setConfirmationMessage('');
		}
	  };
	  



	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [guideToDelete, setGuideToDelete] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState('');

	const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);


	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	const ActionMenu = ({ id, setGuideId }) => {
		return (
			<Dropdown style={{ zIndex: 1000, position:"absolute" }}>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1"
						onClick={() => setGuideId(id)}
					>
						{' '}

						<Edit
							size="15px"
							className="dropdown-item-icon"
						/>{' '}
						Edit
					</Dropdown.Item>
					<Dropdown.Item eventKey="2"
						onClick={() => handleDeleteGuide(id)}
					>
						{' '}
						<Trash
							size="15px"
							className="dropdown-item-icon"
							variant="danger"
							onClick={confirmDeleteGuide}
							

						/> Remove
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			

			{
				accessor: 'fr_articletitle',
				Header: 'French Title',
				Cell: ({ value }) => {
					return value;
				}
			},
		
			{
				accessor: 'category',
				Header: 'Category',
				Cell: ({ row }) => {
				  return row.original.category[0]?.fr_title || 'N/A';
				},
			  },
			{
				accessor: '',
				Header: 'Action',
				Cell: ({ row }) => {

					return (
						<div className="hstack gap-4">
							<ActionMenu id={row.original._id} setGuideId={setGuideId} />
						</div>
					);
				}
			}
		],
		[]
	);

    const data = useMemo(() => guides, [guides]);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Students"
						/>
					</Col>
				</Row>
			</div>

			<Table
				hover
				responsive
				{...getTableProps()}
				className="text-nowrap table-centered"
			>
				<thead className="table-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Deletion</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to delete this guide?</p>
					<h4 className="text-secondary" >you must write "DELETE GUIDE" to confirm the deletion</h4>
					<input
						className="form-control"
						rows="3"
						placeholder="Enter confirmation message"
						value={confirmationMessage}
						onChange={(e) => setConfirmationMessage(e.target.value)}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
						Cancel
					</Button>
					<Button variant={confirmationMessage !== 'DELETE GUIDE' ? 'secondary' : 'danger'}
						disabled={confirmationMessage !== 'DELETE GUIDE'} onClick={confirmDeleteGuide}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default FrenchGuide;
