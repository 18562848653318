import {
  ADD__QUIZS_TO_SESSION,
  DELETE__QUIZS_FROM_SESSION,
  GET_ALL_QUIZS,
  GET_ESSAY_QUESTIONS,
  GET_RESULT_FOR_EACH_READER,
  GET_RESULT_IN_SESSION,
  GET__QUIZS_IN_SESSION,
} from "actions/constants";

const quizsReducer = (state = { quizs: [], session_quizs: [], results:[], essayQuestions:[], resultsForEachReader:[] }, action) => {
  switch (action.type) {
    case GET_ALL_QUIZS:
      return { ...state, quizs: action?.payload };
    case GET__QUIZS_IN_SESSION:
      return { ...state, session_quizs: action?.payload };
    case GET_RESULT_FOR_EACH_READER:
      return { ...state, resultsForEachReader: action?.payload };
    case GET_ESSAY_QUESTIONS:
      return {...state, essayQuestions: action?.payload};  
    case GET_RESULT_IN_SESSION:
      return{...state, results:action.payload};
    case ADD__QUIZS_TO_SESSION:
      return {
        ...state,
        session_quizs: [...state.session_quizs, action?.payload],
      };

    case DELETE__QUIZS_FROM_SESSION:
      return {
        ...state,
        session_quizs: state.session_quizs.filter(
          (e) => e.token !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default quizsReducer;
