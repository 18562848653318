import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // You can choose the storage type

// Import your reducers here
import AppConfigReducer from "./AppConfigReducer";
import authReducer from "./AuthReducer";
import packsReducer from "./PacksReducer";
import usersReducer from "./UsersReducer";
import teachersReducer from "./TeachersReducer";
import booksReducer from "./BooksReducer";
import sessionsReducer from "./SessionsReducer";
import followedPackReducer from "./FollowedPackReducer";
import followedSessionReducer from "./FollowedSessionReducer";
import quizsReducer from "./QuizsReducer";
import faqReducer from "./FaqReducer";
import guideReducer from "./GuideReducer";
import categoryReducer from "./CategoryReducer";
import ticketReducer from "./TicketReducer";

// Combine your reducers using combineReducers
const rootReducer = combineReducers({
  appConfig: AppConfigReducer,
  auth: authReducer,
  packs: packsReducer,
  users: usersReducer,
  teachers: teachersReducer,
  books: booksReducer,
  sessions: sessionsReducer,
  followedPack: followedPackReducer,
  followedSession: followedSessionReducer,
  quizs: quizsReducer,
  reader_in_session: sessionsReducer,
  faqs:faqReducer,
  guides:guideReducer,
  categorys:categoryReducer,
  tickets:ticketReducer,
});

const persistConfig = {
  key: "root", // key for the storage
  storage, // the storage type you imported
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

export { store, persistor };
