import { Button, Form, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createFaqAction, editFaqAction, fetchOneFaqAction } from 'actions/Faq';




const AddNewFaq = ({ showOffcanvas, setShowOffcanvas, faqId, setFaqId }) => {
    // const { loading } = useSelector((state) => state.auth);
    const [faq, setFaq] = useState({
        en_title: "",
        en_content: "",
        fr_title: "",
        fr_content: "",
        ar_title: "",
        ar_content: "",
        category: "MostAsked",

    });

    const { faq: faqEdited } = useSelector(state => state.faqs)
    const dispatch = useDispatch();

    const handleClose = () => {
        setFaq({
            en_title: "",
            en_content: "",
            fr_title: "",
            fr_content: "",
            ar_title: "",
            ar_content: "",
            category: "MostAsked",
        })
        setShowOffcanvas(false)
        setFaqId(null)
    }
    const handleShow = () => setShowOffcanvas(true);
    const handleAddFaq = async (e) => {
        if (faqId) {
            dispatch(editFaqAction(faq, faqId, handleClose, setFaqId))
        } else {
            dispatch(createFaqAction(faq, handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (faqId) {
            dispatch(fetchOneFaqAction(faqId))
        }
    }, [dispatch, faqId])
    useEffect(() => {
        if (faqEdited?._id) {
            setFaq({
                en_title: faqEdited.en_title,
                en_content: faqEdited.en_content,
                fr_title: faqEdited.fr_title,
                fr_content: faqEdited.fr_content,
                ar_title: faqEdited.ar_title,
                ar_content: faqEdited.ar_content,
                category: faqEdited.category,

            })
        }
    }, [faqEdited])




    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow} style={{ marginBottom: "20px" }}>
                Add New FAQ
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{faqId ? 'Update FAQ' : 'Add New FAQ'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>English Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Title"
                                id="en_title"
                                required
                                value={faq.en_title}
                                onChange={(e) =>
                                    setFaq({ ...faq, en_title: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>English Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Content"
                                id="en_content"
                                value={faq.en_content}
                                required
                                onChange={(e) =>
                                    setFaq({ ...faq, en_content: e.target.value })
                                }
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>French Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="titre"
                                id="fr_title"
                                required
                                value={faq.fr_title}
                                onChange={(e) =>
                                    setFaq({ ...faq, fr_title: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>French Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Contenu"
                                id="fr_content"
                                value={faq.fr_content}
                                required
                                onChange={(e) =>
                                    setFaq({ ...faq, fr_content: e.target.value })
                                }
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Arabic Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="عنوان"
                                id="ar_title"
                                required
                                value={faq.ar_title}
                                onChange={(e) =>
                                    setFaq({ ...faq, ar_title: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Arabic Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="محتوى"
                                id="ar_content"
                                value={faq.ar_content}
                                required
                                onChange={(e) =>
                                    setFaq({ ...faq, ar_content: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* PassWord  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Select
                                id="category"
                                value={faq.category}
                                onChange={(e) => setFaq({ ...faq, category: e.target.value })}
                            >
                                <option value="MostAsked">Most Asked</option>
                                <option value="GeneralInquiries">General Inquiries</option>
                                <option value="QuizandScoring">Quiz and Scoring</option>
                                <option value="PointsRankingPrize">Points, Ranking, and Prize</option>
                                <option value="ReadingMoreBooks">Reading More Books for Points</option>
                                <option value="MultipleAccountManagement">Multiple Account Management</option>
                            </Form.Select>
                        </Form.Group>

                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                /* disabled={loading} */
                                onClick={(e) => handleAddFaq(e)}>
                                {faqId ? 'Update FAQ' : 'Add New FAQ'}
                            </Button>

                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewFaq;