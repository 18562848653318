import { CREATE_CATEGORY, DELETE_CATEGORY, EDIT_CATEGORY, GET_ALL_CATEGORY,  GET_ONE_CATEGORY } from "../actions/constants";

const categoryReducer = (state = { categorys:[],category:{} }, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY:
      return { ...state, categorys: action?.payload };
      case GET_ONE_CATEGORY:
        return { ...state, category: action?.payload };
      case EDIT_CATEGORY:
        return { ...state, categorys: state.categorys.map(e=>{
          if(e._id==action.payload._id){
            return action.payload
          }
          else{
            return e
          }
        }) };
      case DELETE_CATEGORY:
        return { ...state, categorys: state.categorys.filter(e => e._id !== action.payload) };
      case CREATE_CATEGORY:
        return { ...state, categorys: [...state.categorys, action.payload] };
    default:
      return state;
  }
};

export default categoryReducer;
