// Import the API functions for category operations
import {
    createCategory,
    deleteCategory,
    editCategory,
    fetchAllCategory,
    fetchCategory,
  } from "api";
  
  import {
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    EDIT_CATEGORY,
    GET_ALL_CATEGORY,
    GET_ONE_CATEGORY,
    LOADING,
  } from "./constants";
  
 
  import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
  export const fetchAllCategoryAction = () => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await fetchAllCategory();
      dispatch({ type: GET_ALL_CATEGORY, payload: data?.guideCategories });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };
  

  export const fetchOneCategoryAction = (_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await fetchCategory(_id);
      dispatch({ type: GET_ONE_CATEGORY, payload: data.guideCategory });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };
  
  
  export const editCategoryAction = (fromData, id, handleClose, setCategoryId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await editCategory(fromData, id);
      dispatch({ type: EDIT_CATEGORY, payload: data.guideCategory });
      handleClose();
      setCategoryId();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };
  
  export const deleteCategoryAction = (_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await deleteCategory(_id);
      dispatch({ type: DELETE_CATEGORY, payload: _id });
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };
  
  export const createCategoryAction = (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const { data } = await createCategory(fromData);
      dispatch({ type: CREATE_CATEGORY, payload: data.guideCategory });
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
    }
  };
  