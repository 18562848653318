import { CREATE_GUIDE, DELETE_GUIDE, EDIT_GUIDE, GET_ALL_GUIDE,  GET_ONE_GUIDE } from "../actions/constants";

const guideReducer = (state = { guides:[],guide:{} }, action) => {
  switch (action.type) {
    case GET_ALL_GUIDE:
      return { ...state, guides: action?.payload };
      case GET_ONE_GUIDE:
        return { ...state, guide: action?.payload };
      case EDIT_GUIDE:
        return { ...state, guides: state.guides.map(e=>{
          if(e._id==action.payload._id){
            return action.payload
          }
          else{
            return e
          }
        }) };
      case DELETE_GUIDE:
        return { ...state, guides: state.guides.filter(e => e._id !== action.payload) };
      case CREATE_GUIDE:
        return { ...state, guides: [...state.guides, action.payload] };
    default:
      return state;
  }
};

export default guideReducer;
