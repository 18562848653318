import {
  AUTH,
  CLEAR_CURENT,
  FETCH_DASHBORD_ANALYTICS,
  LOADING,
  LOGIN,
  LOGOUT,
} from "../actions/constants";

const authReducer = (
  state = { is_authenticated: false, loading: false, dashbord_analytics: {} },
  action
) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case AUTH:
      localStorage.setItem("user", JSON.stringify({ ...action?.payload }));
    case LOGIN:
      return {
        ...state,
        user: action?.payload,
        is_authenticated: true,
      };
    case LOGOUT:
      

      return { ...state, user: null, is_authenticated: false };

    case CLEAR_CURENT:
      localStorage.removeItem("user");

      return { ...state, user: null, is_authenticated: false };

    case FETCH_DASHBORD_ANALYTICS:
      return { ...state, dashbord_analytics: action?.payload };
    default:
      return state;
  }
};

export default authReducer;
