import { PacksDetailsData } from 'data/courses/BootstrapCoursesData';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Import Typeahead CSS
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCategoryAction } from 'actions/category';

const AutocompleteCategory = ({selectedOption, setSelectedOption}) => {
	const dispatch = useDispatch();
	
	const {categorys} = useSelector(state => state.categorys);
	useEffect(() => {
		dispatch(fetchAllCategoryAction())
	  }, [dispatch]);

    const handleSelection = (selected) => {
      // Check if selected is not empty and has the en_title property
      if (selected && selected.length > 0 && selected[0].en_title) {
        const enTitle = selected[0].en_title;
      }
    
      // Set the state with the entire selected object
      setSelectedOption(selected);
    };
  const options = [
    ...categorys.map(item => item),
    
  ];

  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="en_title" 
        options={options}
        placeholder="Type to search..."
        selected={selectedOption}
        onChange={handleSelection}
      />
    </div>
  );
};

export default AutocompleteCategory;
