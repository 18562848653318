// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    Image,
    OverlayTrigger,
    Tooltip,
    Row,
    Col,
    Table,
    Button,
    Modal
} from 'react-bootstrap';
import { MoreVertical, Trash, Edit, Mail, Eye } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteTicketAction, fetchAllTicketAction } from 'actions/tickets';




const TicketTable = ({ setTicketId }) => {


    const { tickets } = useSelector(state => state.tickets);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllTicketAction())
    }, [dispatch])

    const handleDeleteTicket = (id) => {
        setTicketToDelete(id);
        setShowDeleteModal(true);
    };

    const confirmDeleteTicket = () => {
        if (ticketToDelete && confirmationMessage === 'DELETE TICKET') {
            dispatch(deleteTicketAction(ticketToDelete));
            setTicketToDelete(null);
            setShowDeleteModal(false);
            setConfirmationMessage('');
        }
    };



    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [ticketToDelete, setTicketToDelete] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(null);

    const handleDetailsTicket = (rowData) => {
        setSelectedDetails(rowData);
        setShowDetailsModal(true);
    };
    const handleCloseDetails = () => {
        setSelectedDetails(null);
        setShowDetailsModal(false);
    };


    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="btn-icon btn btn-ghost btn-sm rounded-circle"
        >
            {children}
        </Link>
    ));

    const ActionMenu = ({ id, setTicketId }) => {
        return (
            <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
                <Dropdown.Toggle as={CustomToggle}>
                    <MoreVertical size="15px" className="text-secondary" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                    <Dropdown.Header>SETTINGS</Dropdown.Header>
                    {/* <Dropdown.Item eventKey="1"
						onClick={() => setTicketId(id)}
					>
						{' '}

						<Edit
							size="15px"
							className="dropdown-item-icon"
						/>{' '}
						Edit
					</Dropdown.Item> */}
                    <Dropdown.Item eventKey="2"
                        onClick={() => handleDeleteTicket(id)}
                    >
                        {' '}
                        <Trash
                            size="15px"
                            className="dropdown-item-icon"
                            variant="danger"
                            onClick={confirmDeleteTicket}


                        /> Remove
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const columns = useMemo(
        () => [


            {
                accessor: 'name',
                Header: 'User Name',
                Cell: ({ value }) => {
                    return value;
                }
            },
            {
                accessor: 'email',
                Header: 'Email',
                Cell: ({ value }) => {
                    return value;
                }
            },
            {
                accessor: 'subject',
                Header: 'Subject',
                Cell: ({ value }) => {
                    return value;
                }
            },
            {
                accessor: 'description',
                Header: 'Subject Details',
                Cell: ({ row }) => {
                    return (
                        <div className="hstack gap-4">
                            <Button onClick={() => handleDetailsTicket(row.original)}>
                                <Eye
                                    color='white'
                                    size="15px"
                                    className="dropdown-item-icon"
                                />
                                View Details
                            </Button>

                        </div>
                    );
                }
            },
            {
                accessor: '',
                Header: 'Action',
                Cell: ({ row }) => {

                    return (
                        <div className="hstack gap-4">
                            <ActionMenu id={row.original._id} setTicketId={setTicketId} />
                        </div>
                    );
                }
            }
        ],
        []
    );

    const data = useMemo(() => tickets, [tickets]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        state,
        gotoPage,
        pageCount,
        prepareRow,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10,
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) return column.accessor || column.id;
                    else return false;
                })
            }
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, globalFilter } = state;

    return (
        <Fragment>
            <div className=" overflow-hidden">
                <Row>
                    <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
                        <GlobalFilter
                            filter={globalFilter}
                            setFilter={setGlobalFilter}
                            placeholder="Search Students"
                        />
                    </Col>
                </Row>
            </div>

            <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
            >
                <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this category?</p>
                    <h4 className="text-secondary" >you must write "DELETE TICKET" to confirm the deletion</h4>
                    <input
                        className="form-control"
                        rows="3"
                        placeholder="Enter confirmation message"
                        value={confirmationMessage}
                        onChange={(e) => setConfirmationMessage(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant={confirmationMessage !== 'DELETE TICKET' ? 'secondary' : 'danger'}
                        disabled={confirmationMessage !== 'DELETE TICKET'} onClick={confirmDeleteTicket}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDetailsModal} onHide={handleCloseDetails}>
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedDetails && (
                        <div>
                            <p><strong>User Name:</strong> {selectedDetails.name}</p>
                            <p><strong>Email:</strong> {selectedDetails.email}</p>
                            <p><strong>Subject:</strong> {selectedDetails.subject}</p>
                            <p><strong>Subject Details:</strong> {selectedDetails.description}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDetails}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Pagination @ Footer */}
            <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
            />
            <ToastContainer />
        </Fragment>
    );
};

export default TicketTable;
